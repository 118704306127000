// Import everything from autoload folder
import './autoload/_bootstrap.js'; import './autoload/ada-compliance.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import './plugins/google-map';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import { Fancybox } from '@fancyapps/ui';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';
/*global  ajax_object*/

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  function postMessageToPlayer(player, command) {
    if (player == null || command == null) return;
    player.contentWindow.postMessage(JSON.stringify(command), '*');
  }

  function videoOverlay() {
    const videoSection = $('.video-section');
    if (!videoSection.length) return;
    const playBtn = videoSection.find('.js-play-btn');
    if (!playBtn.length) return;
    const thumbnail = videoSection.find('.js-thumbnail');
    const video = videoSection.find('.js-video');
    const player = videoSection.find('iframe').get(0);

    if (player) {
      const playerSrc = player.src;
      player.src = playerSrc.replace(
        /\?feature=oembed/,
        '?feature=oembed&enablejsapi=1'
      );
    }

    playBtn.on('click', function () {
      thumbnail.fadeOut();
      if (video.length) {
        video.trigger('play');
      }
      if (player) {
        postMessageToPlayer(player, {
          event: 'command',
          func: 'playVideo',
        });
      }
    });
  }

  videoOverlay();

  $('#input_2_13').on('change', function () {
    var filePath = $(this)
      .val()
      .replace(/.*(\/|\\)/, '');
    console.log($(this).val());
    $('label[for="input_2_13"]').text(filePath);
  });
  $('#input_4_14').on('change', function () {
    var filePath = $(this)
      .val()
      .replace(/.*(\/|\\)/, '');
    console.log($(this).val());
    $('label[for="input_4_14"]').text(filePath);
  });
  // body lock mobile for menu
  $('.navbar-toggler').on('click', function () {
    $('body').toggleClass('lock');
    $('.navbar').toggleClass('show');
    $(this).toggleClass('active');
  });
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * Testimonials SLider
   */
  $('.testimonials__slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 700,
    autoplay: true,
    autoplaySpeed: 4000,
    dots: false,
    arrow: true,
  });

  /**
   * Ajax Blog Page
   */

  let catBtn = $('.cat-list__link');
  catBtn.on('click', function () {
    let category = $(this).attr('data-slug');
    catBtn.removeClass('is-active');
    $(this).addClass('is-active');
    $.ajax({
      type: 'POST',
      url: ajax_object.ajax_url, // get from wp_localize_script()
      data: {
        action: 'category_blog_filter', // action for wp_ajax_ & wp_ajax_nopriv_
        category: category, //chosen category
      },
      beforeSend: function () {},
      success: function (data) {
        $('.posts-wrap').html(data.data);
        $('.posts-list').attr('data-category', category);
      },
    });
  });
  $(document).on('click', 'ul.my-pagination li a', function (e) {
    e.preventDefault();
    let max_page = $('.posts-list').data('max-pages'),
      category = $('.posts-list').data('category'),
      current_page = $(this).data('page'),
      data = {
        action: 'blog_loadmore',
        page: current_page,
        max_page: max_page,
        category: category,
      };
    $.ajax({
      // you can also use $.post here
      url: ajax_object.ajax_url, // AJAX handler
      data: data,
      type: 'POST',
      beforeSend: function () {
        // button.text('Loading...'); // change the button text, you can also add a preloader image
      },
      success: function (data) {
        if (data) {
          $('.posts-list').html(data.loop); // insert new posts
          $('.my-pagination').html(data.pagin);
        } else {
          // button.remove(); // if no data, remove the button as well
        }
      },
    });
  });
  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  $('.gallery-item')
    .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
    .attr('rel', 'gallery')
    .attr('data-fancybox', 'gallery');

  Fancybox.bind('[data-fancybox]');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    // jQuery code goes here
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
